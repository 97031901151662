import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { usePrevious } from "../../hooks";

const ArticleListing = dynamic(() => import('../../components/ArticleListing'));
const LoadMore = dynamic(() => import('../../components/LoadMore'));

export async function getServerSideProps({ query }) {
  const page = query.page || 1;
  let data;
  const stories = await fetch(`${process.env.NEXT_PUBLIC_API_PROTO}://${process.env.NEXT_PUBLIC_API_HOST}:${process.env.NEXT_PUBLIC_API_PORT}/api/${process.env.NEXT_PUBLIC_API_VERSION}/latest-stories?page=${page}`);
  data = await stories.json()
  return {
    props: {
      stories: data
    },
  };
}

function HomePage(props) {
  const { stories } = props;
  const router = useRouter();
  const [data, setData] = useState(stories.list)
  const previousValue = usePrevious(data);

  const nextPage = stories.pagination ? stories.pagination.current_page : 1

  useEffect(() => {
    if(previousValue) {
      setData([...previousValue, ...stories.list])
    }
  }, [previousValue]);

  return (
    <>
      <ArticleListing info={stories} data={data} />
      <LoadMore current_page={nextPage} router={router} />
    </>
  );
}

export default HomePage