import { useState, useEffect, useRef, useContext } from "react";

/**
 * This function handles the pagination on the listing pages
 * 
 * @param {*} current_page 
 * @param {*} router 
 */

export const handlePagination = (current_page, router) => {
  const route = router.asPath;
  const query = router.query;
  query.page = parseInt(query.page ? query.page : current_page) + 1;
  router.push(
    {
      pathname: router.pathname,
      query: query,
      shallow: true,
    },
    undefined,
    { scroll: false }
  );
}; 

/**
 * This function handles the infinite scroll on the articles
 * page, once the desired element is in view this function is 
 * called
 * 
 * @param {*} next_page 
 * @param {*} router 
 */

export const handleNextArticle = (next_page, router) => {
  const query = router.query
  query.page = next_page
  router.push({
    pathname: router.pathname,
    query: query,
    shallow: true 
  }, next_page, { scroll: false });
}

/**
 * Hook for keeping previous values
 * Useful for when you need to access old data
 * 
 * @param {*} value 
 * @returns 
 */
export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

